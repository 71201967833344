import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import CulturePoster from '../components/CulturePoster/CulturePoster'
import '../styles/components/timeline.css'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Arrow from '../components/SVG/Arrow'

const AboutPage = ({ data, location }) => {
  const page = data.prismicAbout.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pt-32 lg:pt-36 mb-0 lg:mb-0" id="our-journey">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">
                {page.about_us_title.text}
              </h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">
                {page.about_us_title.text}
              </h1>
            </div>
            <div className="grid__half--secondary rte pt-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: page.about_us_content_above_image.html,
                }}
              />
              <img
                className="mx-auto"
                src={page.about_us_image.url}
                alt={page.about_us_image.alt}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: page.about_us_content_below_image.html,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 mb-10 lg:mb-0 lg:pt-20 lg:pb-20" id="achievements">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">
                {page.our_achievements_title.text}
              </h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden pt-8">
              <h1 className="type-lg lg:type-3xl text-white">
                {page.our_achievements_title.text}
              </h1>
            </div>
            <div className="grid__half--secondary rte pt-4">
              <div className="timeline">
                {page.our_achievements_group.map((achievement, index) => {
                  return (
                    <>
                      <div className="timeline-container right">
                        <div className="timeline-content">
                          <div>
                            <h2>{achievement.achievement_group_date}</h2>
                            <h3>{achievement.achievement_group_title}</h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  achievement.achievement_group_description
                                    .html,
                              }}
                            />
                          </div>
                          <div>
                            <img
                              src={achievement.our_achievements_group_image.url}
                              alt={`hi`}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-0 lg:pt-18 lg:mb-10" id="our-team">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white" id="achievements">
                {page.our_people_title.text}
              </h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">
                {page.our_people_title.text}
              </h1>
            </div>

            <div className="grid__half--secondary lg:type-lg">
              <div className="grid__half--secondary rte pt-4 lg:pb-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page.our_people_text.html,
                  }}
                />
              </div>
              <h2 className="type-lg lg:type-2xl mt-6 lg:mt-0 mb-6 lg:mb-12">
                {page.strategic_advisory_group_title}
              </h2>

              <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                <div className="lg:flex flex-wrap justify-between -mb-12">
                  {page.strategic_advisory_group.map((item, i) => {
                    return (
                      <div className="lg:w-11/23 mb-12 card">
                        <AccordionItem onChange={() => {}} key={i}>
                          <AccordionItemHeading className="card__header">
                            <AccordionItemButton className="focus:outline-none">
                              <AccordionItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    <>
                                      <div className="mb-4 relative">
                                        <img
                                          src={
                                            item.strategic_advisory_group_image
                                              .url
                                          }
                                          alt={
                                            item.strategic_advisory_group_image
                                              .alt
                                          }
                                        />
                                        <div className="card__cover opacity-75">
                                          <div className="text-white pl-4 pt-3">
                                            Read more
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-white">
                                        <h3 className="lg:type-xl">
                                          {item.strategic_advisory_group_name}
                                        </h3>
                                        <div className="flex items-center justify-between">
                                          <h4 className="type-sm">
                                            {item.strategic_advisory_group_role}
                                          </h4>

                                          <div className="w-4">
                                            <svg
                                              className="stroke-current w-full"
                                              viewBox="0 0 14 7"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14 6L7 1 0 6"
                                                fill="none"
                                                fillRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-4 relative">
                                        <img
                                          src={
                                            item.strategic_advisory_group_image
                                              .url
                                          }
                                          alt={
                                            item.strategic_advisory_group_image
                                              .alt
                                          }
                                        />
                                        <div className="card__cover">
                                          <div className="text-white pl-4 pt-3">
                                            Read more
                                          </div>
                                        </div>
                                      </div>

                                      <h3 className="lg:type-xl">
                                        {item.strategic_advisory_group_name}
                                      </h3>
                                      <div className="flex items-center justify-between">
                                        <h4 className="type-sm">
                                          {item.strategic_advisory_group_role}
                                        </h4>

                                        <div className="w-4">
                                          <svg
                                            className="stroke-current w-full"
                                            viewBox="0 0 16 7"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 0l7 7 7-7"
                                              fill="none"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </AccordionItemState>
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          <AccordionItemPanel>
                            <div
                              className="rte type-sm mt-4 lg:mt-10"
                              dangerouslySetInnerHTML={{
                                __html: item.strategic_advisory_group_text.html,
                              }}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    )
                  })}
                </div>
              </Accordion>

              <h3 className="type-lg lg:type-2xl pb pt">{page.team_title}</h3>

              <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                <div className="lg:flex flex-wrap justify-between -mb-12">
                  {page.team.map((item, i) => {
                    return (
                      <div className="lg:w-11/23 mb-12 card">
                        <AccordionItem onChange={() => {}} key={i}>
                          <AccordionItemHeading className="card__header">
                            <AccordionItemButton className="focus:outline-none">
                              <AccordionItemState>
                                {({ expanded }) =>
                                  expanded ? (
                                    <>
                                      <div className="mb-4 relative">
                                        <img
                                          src={item.team_image.url}
                                          alt={item.team_image.alt}
                                        />
                                        <div className="card__cover opacity-75">
                                          <div className="text-white pl-4 pt-3">
                                            Read more
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-white">
                                        <h3 className="lg:type-xl">
                                          {item.team_name}
                                        </h3>
                                        <div className="flex items-center justify-between">
                                          <h4 className="type-sm">
                                            {item.team_role}
                                          </h4>

                                          <div className="w-4">
                                            <svg
                                              className="stroke-current w-full"
                                              viewBox="0 0 14 7"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M14 6L7 1 0 6"
                                                fill="none"
                                                fillRule="evenodd"
                                              />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-4 relative">
                                        <img
                                          src={item.team_image.url}
                                          alt={item.team_image.alt}
                                        />
                                        <div className="card__cover">
                                          <div className="text-white pl-4 pt-3">
                                            Read more
                                          </div>
                                        </div>
                                      </div>

                                      <h3 className="lg:type-xl">
                                        {item.team_name}
                                      </h3>
                                      <div className="flex items-center justify-between">
                                        <h4 className="type-sm">
                                          {item.team_role}
                                        </h4>

                                        <div className="w-4">
                                          <svg
                                            className="stroke-current w-full"
                                            viewBox="0 0 16 7"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M1 0l7 7 7-7"
                                              fill="none"
                                              fillRule="evenodd"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </AccordionItemState>
                            </AccordionItemButton>
                          </AccordionItemHeading>

                          <AccordionItemPanel>
                            <div
                              className="rte type-sm mt-4 lg:mt-10"
                              dangerouslySetInnerHTML={{
                                __html: item.team_text.html,
                              }}
                            />
                          </AccordionItemPanel>
                        </AccordionItem>
                      </div>
                    )
                  })}
                </div>
              </Accordion>

              <div>
                <div className="grid__half--secondary-row-two lg:type-lg pb-6 pt-6 lg:pb-16 lg:pt-16">
                  <h3 className="type-lg lg:type-2xl">{page.partners_title}</h3>
                </div>

                <div className="grid__half--secondary-row-three m-auto lg:m-0">
                  <div className="partners">
                    {page.partners.map((item, i) => {
                      return (
                        <div className="max-w-sm">
                          <a href={item.partner_link.url}>
                            <Img
                              fluid={
                                item.partner_image.localFile.childImageSharp
                                  .fluid
                              }
                            />
                          </a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-6 lg:pt-0 pb-12 lg:pb-32" id="our-values">
        <div className="grid">
          <div className="grid__half--primary">
            <div className="w-3/4 sticker">
              <h2 className="type-lg lg:type-3xl text-white">
                {page.our_values_title.text}
              </h2>
            </div>
          </div>

          <div className="grid__half--secondary pt-4" id="our-values">
            <CulturePoster />
          </div>
          <div />
        </div>
      </div>

      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        className="bg-yellow"
      >
        <AccordionItem key={page.legal_title}>
          <div className="grid py-8">
            <div className="grid__half--primary">
              <AccordionItemHeading className="hover:opacity-50 focus:opacity-75 transition-ease">
                <AccordionItemButton className="accordion-item-button focus:outline-none">
                  <div className="mb-4">
                    <h3 className="accordion-item-button__heading type-lg lg:type-xl inline-block mr-12">
                      {page.legal_title}
                    </h3>

                    <div className="accordion-item-button__icon inline-block w-8 text-black">
                      <Arrow />
                    </div>
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
            </div>
          </div>
          <div className="grid__half--secondary" />

          <AccordionItemPanel>
            <div className="grid pt-16 pb-8 items-end">
              <div
                className="grid__half--secondary rte"
                dangerouslySetInnerHTML={{ __html: page.legal_body.html }}
              />
            </div>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Layout>
  )
}

AboutPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageAboutQuery = graphql`
  query {
    prismicAbout {
      data {
        title
        lead {
          html
        }
        body {
          html
        }
        progress_title {
          text
        }
        puro_progress_image {
          url
          alt
        }
        puro_culture_image {
          url
          alt
        }
        testimonial {
          html
          text
        }
        testimonial_credit
        values_title {
          html
          text
        }
        values_image {
          alt
          copyright
          url
        }
        values {
          value_text {
            html
            text
          }
          value_title
        }
        our_achievements_group {
          achievement_group_date
          achievement_group_title
          achievement_group_description {
            html
          }
          our_achievements_group_image {
            url
            alt
          }
        }

        legal_title
        legal_body {
          html
          text
        }
        about_us_title {
          html
          text
        }
        about_us_content_above_image {
          html
          text
        }
        about_us_content_below_image {
          html
          text
        }
        about_us_image {
          url
          alt
        }
        our_values_title {
          html
          text
        }
        our_values_content_above_accordian {
          html
          text
        }
        our_values_content_below_accordian {
          html
          text
        }
        our_values_image {
          url
          alt
        }
        our_achievements_title {
          html
          text
        }
        our_achievements_content {
          html
          text
        }
        our_people_title {
          text
        }
        our_people_text {
          html
        }
        strategic_advisory_group_title
        strategic_advisory_group {
          strategic_advisory_group_name
          strategic_advisory_group_role
          strategic_advisory_group_text {
            html
            text
          }
          strategic_advisory_group_image {
            alt
            copyright
            url
          }
        }
        professional_advisers_title
        after_puro_advisers_body {
          html
        }
        team_title
        team {
          team_name
          team_role
          team_text {
            text
            html
          }
          team_image {
            alt
            copyright
            url
          }
        }
        partners_title
        partners {
          partner_image {
            alt
            copyright
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          partner_name
          partner_link {
            url
          }
        }

        meta_title
        meta_description
      }
    }
  }
`

export default AboutPage
