import React from 'react'

import FooterMenu from '../FooterMenu'
import Logo from '../Logo'
import IconFacebook from '../SVG/IconFacebook'
import IconInstagram from '../SVG/IconInstagram'

const CulturePoster = ({ bg, settings }) => (
  <div className="grid-container culture-poster">
    <div className="span rte">
      <p>
        Puro is driven by a clear vision and strong, robust values that underpin
        all we do. Our culture and ethnical business ethos is been built around
        tenacity, knowledge, passion and performance. We are focused on
        achieving excellence in everything we do, for our people, our community
        and our land. Sustainability and teamwork is a big part of that, and
        it’s the reason we have taken our organic approach and are on a journey
        towards regenerative agriculture.
      </p>
      <p>
        Puro’s culture is summarised by our seven cultural pillars defined as{' '}
        <strong>‘ARTISTS’</strong> – an acronym that captures our values and
        ethics. It is who we are and what we stand for.
      </p>
      <p>
        Puro is growing the future. We are pioneering an industry in Aotearoa.
        Kaitiakitanga, integrity and excellence are in our heart and at the core
        of everything we do.
      </p>

      <div className="definition">
        <h3>
          Artists<span> (/ˈärdəst/)</span>
        </h3>

        <p>
          <em>
            &ndash; noun: artist; <br />
          </em>
        </p>
        <p>
          <em>
            &ndash; Artists &mdash; Persons skilled at a particular task or
            occupation
          </em>
        </p>
      </div>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/baa4547a-e34e-4fdf-a8e6-8671aeb6dc7f_Achieve.png?auto=compress,format" />
      <h4>Achievement</h4>
      <p>We work hard, get results and always strive to improve.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/b55319e5-c25e-43d3-a545-d2544924415d_Respect.png?auto=compress,format" />
      <h4>Respect</h4>
      <p>We treat everybody in the same way we wish to be treated.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/0b819345-eaaf-4fc4-b3a9-183881b1216b_Tenacity.png?auto=compress,format" />
      <h4>Tenacity</h4>
      <p>We innovate, adapt and never give up.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/6e137b88-a22b-4f0c-b7f5-339d4224ac64_Integrity.png?auto=compress,format" />
      <h4>Integrity</h4>
      <p>We live our values everyday.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/77e1f359-656c-44e0-b063-91c9318300ff_Sustainability.png?auto=compress,format" />
      <h4>Sustainability</h4>
      <p>We value our peple, our customers and our planet.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/c3358aac-c447-4aef-b67e-c80a5b24b0c0_Team.png?auto=compress,format" />
      <h4>Teamwork</h4>
      <p>We support each other, celebrate wins and have fun.</p>
    </div>
    <div className="culture-item">
      <img src="https://images.prismic.io/puro001/86761757-be98-4f9f-bb83-96e119133450_Safety.png?auto=compress,format" />
      <h4>Safety</h4>
      <p>We protect the safety of our people, our planet and our product.</p>
    </div>
  </div>
)

export default CulturePoster
