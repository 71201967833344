import React from 'react'

const Arrow = () => {
  return (
    <svg
      className="w-full stroke-current"
      viewBox="0 0 33 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 .5h27v13" />
        <path d="M22 8.5l5 5 5-5" />
      </g>
    </svg>
  )
}

export default Arrow
